import React, { useState, useEffect } from "react";
import { Breadcrumb, Statistic, Card, Row, Col, PageHeader } from "antd";
import { Bar } from "@ant-design/plots";
import {
  ShoppingCartOutlined,
  PhoneOutlined,
  RightOutlined,
  UserOutlined,
  UserDeleteOutlined,
  FormOutlined,
  BulbOutlined,
  DollarOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getCountUsersApi } from "../../api/user.api";
import {
  getMaxHaveApi,
  getHaveGroupApi,
  getHaveTotalApi,
} from "../../api/have.api";
import {
  getMaxNeedApi,
  getNeedGroupApi,
  getNeedTotalApi,
} from "../../api/need.api";

import { getAccessTokenApi } from "../../api/auth";

import "react-leaflet-markercluster/dist/styles.min.css";

export default function Admin() {
  const token = getAccessTokenApi();
  const [countUserActive, setCountUserActive] = useState();
  const [countUserInactive, setCountUserInactive] = useState();
  const [countFincas, setCountFincas] = useState();
  const [countEncuestas, setCountEncuestas] = useState();
  const [have, setHave] = useState([]);
  const [need, setNeed] = useState([]);
  // const [groupHave, setGroupHave] = useState();
  // const [groupNeed, setGroupNeed] = useState();
  //const [loadingN, setLoadingN] = useState(true);
  //const [loadingH, setLoadingH] = useState(true);
  const [needTotal, setNeedTotal] = useState();
  const [haveTotal, setHaveTotal] = useState();

  useEffect(() => {
    // getCountFincaApi(token).then((response) => {
    //   setCountFincas(response.count);
    // });

    // getCountEncuestaApi(token).then((response) => {
    //   setCountEncuestas(response.count);
    // });

    getCountUsersApi(token, true).then((response) => {
      setCountUserActive(response.count);
    });

    getCountUsersApi(token, false).then((response) => {
      setCountUserInactive(response.count);
    });

    getMaxHaveApi(token).then((response) => {
      setHave(response.have);
    });

    getMaxNeedApi(token).then((response) => {
      setNeed(response.need);
    });

    // getNeedGroupApi(token).then((response) => {
    //   const data1 = [];
    //   console.log(response.needs);
    //   let needs1 = response.needs;
    //   needs1.map((need) =>
    //     data1.push({
    //       name: need.name,
    //       count: parseInt(need.count),
    //       type: "Demanda",
    //     })
    //   );
    //   setGroupNeed(data1);
    //   setLoadingN(false);
    // });

    // getHaveGroupApi(token).then((response) => {
    //   const data2 = [];
    //   //console.log(response.haves);
    //   let haves1 = response.haves;
    //   haves1.map((have) =>
    //     data2.push({
    //       name: have.name,
    //       count: parseInt(have.count),
    //       type: "Oferta",
    //     })
    //   );

    //   setGroupHave(data2);
    //   setLoadingH(false);
    // });

    getNeedTotalApi(token).then((response) => {
      //console.log(response.total[0].total);
      setNeedTotal(response.total[0].total);
    });
    getHaveTotalApi(token).then((response) => {
      //console.log(response.total[0].total);
      setHaveTotal(response.total[0].total);
    });
  }, []);

  // const config = {
  //   isStack: true,
  //   xField: "count",
  //   yField: "name",
  //   seriesField: "type",
  //   label: {
  //     position: "middle",
  //     layout: [
  //       {
  //         type: "interval-adjust-position",
  //       },
  //       {
  //         type: "interval-hide-overlap",
  //       },
  //       {
  //         type: "adjust-color",
  //       },
  //     ],
  //   },
  // };
  // console.log(config);

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Panel de Control Administrador </Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <PageHeader
          className="site-page-header header-left"
          // onBack={() => null}
          style={{
            textAlign: "left",
            justifyContent: "start",
            fontSize: "17px",
          }}
          title={
            <>
              <ToolOutlined style={{ fontSize: "17px" }} />
              <span style={{ fontSize: "17px" }}>
                {" "}
                Monitoreo del ecosistema de emprendimiento de la Amazonía
                Ecuatoriana
              </span>
            </>
          }
          // subTitle="This is a subtitle"
        />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <Card
              title="Emprendedores"
              // extra={
              //   <Link to={"/admin/users"}>
              //     <RightOutlined />
              //   </Link>
              // }
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Statistic
                    title="Activos"
                    value={countUserActive}
                    precision={0}
                    valueStyle={{ color: "#3f8600" }}
                    prefix={<UserOutlined />}
                    suffix=""
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Inactivos"
                    value={countUserInactive}
                    precision={0}
                    valueStyle={{ color: "#cf1322" }}
                    prefix={<UserDeleteOutlined />}
                    suffix=""
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={18} xl={18}>
            <Card
              title="Conocimientos"
              // extra={
              //   <Link to={"/encuestas"}>
              //     <RightOutlined />
              //   </Link>
              // }
            >
              <Row gutter={16}>
                <Col span={8}>
                  <Statistic
                    title="Banco de conocimiento"
                    value={needTotal}
                    precision={0}
                    valueStyle={{ color: "#1890ff" }}
                    prefix={<BulbOutlined />}
                    suffix=" horas"
                  />
                </Col>
                <Col span={8}>
                  <Statistic
                    title={have.name + " es lo más ofertado"}
                    value={have.count}
                    precision={0}
                    valueStyle={{ color: "#faad14" }}
                    prefix={<BulbOutlined />}
                    suffix=" horas"
                  />
                </Col>
                <Col span={8}>
                  <Statistic
                    title={need.name + " es lo más demandado"}
                    value={need.count}
                    precision={0}
                    valueStyle={{ color: "#faad14" }}
                    prefix={<BulbOutlined />}
                    suffix=" horas"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col span={24}>
            <Card title="Total en dólares del banco de conocimiento">
              <br />
              <Statistic
                title={
                  "El cálculo está realizado por " +
                  haveTotal +
                  " horas * 30 USD"
                }
                value={haveTotal * 30}
                precision={0}
                valueStyle={{
                  color: "#3f8600",
                  fontSize: "70px",
                  fontWeight: "bolder",
                }}
                prefix={<DollarOutlined />}
                style={{ textAlign: "center" }}
                //suffix=" horas"
              />
              <br />
            </Card>
          </Col>
        </Row>
        {/* <Row gutter={24}>
          <Col span={24}>
            <Card
              title="Oferta y demanda"
              // extra={
              //   <Link to={"/admin/users"}>
              //     <RightOutlined />
              //   </Link>
              // }
            >
              {!loadingH && !loadingN && (
                <Bar
                  data={[...(groupHave ?? []), ...(groupNeed ?? [])]}
                  {...config}
                />
              )}
            </Card>
          </Col>
        </Row> */}
      </div>
    </div>
  );
}
