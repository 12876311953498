import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Empty, Tag, Row, Col, Form, Button } from "antd";
import { Table } from "ant-table-extensions";
import { Bar } from "@ant-design/charts";
import { getAccessTokenApi } from "../../../api/auth";
import SelectAllCityForm from "../../../components/Admin/City/SelectCityForm/SelectAllCityForm";
import SelectRoleOneForm from "../../../components/Admin/Role/SelectRoleForm/SelectRoleOneForm";
import SelectCommunityOneForm from "../../../components/Admin/Community/SelectCommunityForm/SelectCommunityOneForm";
import SelectProvinceAllForm from "../../../components/Admin/Province/SelectProvinceForm/SelectProvinceAllForm";
import "./Indicadores.scss";
//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";
import ExportOutlined from "@ant-design/icons/ExportOutlined";

import { getUsersActiveApi } from "../../../api/user.api";

export default function Registros() {
  const token = getAccessTokenApi();
  const [usersActive, setUsersActive] = useState([]);
  const [city, setCity] = useState(0);
  const [province, setProvince] = useState(0);
  const [role, setRole] = useState(0);
  const [community, setCommunity] = useState(0);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    getUsersActiveApi(token, true, city, role, community, province).then(
      (response) => {
        //console.log(response.users);
        setUsersActive(response.users);
        setLoadingData(false);
      }
    );
  }, [city, community, province, role, token]);

  const columns = [
    {
      title: "Cédula",
      width: 120,
      dataIndex: "ci",
      key: "ci",
      fixed: "left",
    },
    {
      title: "Nombres",
      width: 250,
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Apellidos",
      width: 250,
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Usuario",
      width: 180,
      key: "ci",
      render: (_, record) => (
        <Tag color={"green"} key={"COMPLETO"}>
          {"COMPLETO"}
        </Tag>
      ),
      filters: [
        { text: "COMPLETO", value: "1" },
        { text: "INCOMPLETO", value: "0" },
      ],
      onFilter: (value, record) => {
        return value === "1";
      },
    },
    {
      title: "Emprendimiento",
      key: "action",
      width: 180,
      render: (_, record) => (
        <div>
          {record.entrepreneurships.length > 0 ? (
            <Tag color={"green"} key={"COMPLETO"}>
              {"COMPLETO"}
            </Tag>
          ) : (
            <Tag color={"red"} key={"COMPLETO"}>
              {"NO REGISTRADO"}
            </Tag>
          )}
        </div>
      ),
      filters: [
        { text: "COMPLETO", value: "1" },
        { text: "NO REGISTRADO", value: "0" },
      ],
      onFilter: (value, record) => {
        if (value === "1") {
          return record.entrepreneurships.length > 0;
        } else {
          return record.entrepreneurships.length === 0;
        }
      },
    },
    {
      title: "Yo tengo",
      key: "action",
      width: 180,
      render: (_, record) => (
        <div>
          {record.entrepreneurships.length > 0 &&
          record.entrepreneurships[0].haves.length > 0 ? (
            <Tag color={"green"} key={"COMPLETO"}>
              {record.entrepreneurships[0].haves.length + " REGISTROS"}
            </Tag>
          ) : record.entrepreneurships.length > 0 ? (
            <Tag color={"yellow"} key={"COMPLETO"}>
              {"0 REGISTROS"}
            </Tag>
          ) : (
            <Tag color={"red"} key={"COMPLETO"}>
              {"SIN EMPRENDIMIENTO"}
            </Tag>
          )}
        </div>
      ),
      filters: [
        { text: "CON REGISTROS", value: "1" },
        { text: "SIN REGISTROS", value: "2" },
        { text: "SIN EMPRENDIMIENTO", value: "0" },
      ],
      onFilter: (value, record) => {
        if (value === "1") {
          if (record.entrepreneurships.length > 0) {
            return record.entrepreneurships[0].haves.length > 0;
          } else {
            return false;
          }
        } else if (value === "2") {
          if (record.entrepreneurships.length > 0) {
            return record.entrepreneurships[0].haves.length === 0;
          } else {
            return false;
          }
        } else {
          return record.entrepreneurships.length === 0;
        }
      },
    },
    {
      title: "Yo necesito",
      key: "action",
      width: 180,
      render: (_, record) => (
        <div>
          {record.entrepreneurships.length > 0 &&
          record.entrepreneurships[0].needs.length > 0 ? (
            <Tag color={"green"} key={"COMPLETO"}>
              {record.entrepreneurships[0].needs.length + " REGISTROS"}
            </Tag>
          ) : record.entrepreneurships.length > 0 ? (
            <Tag color={"yellow"} key={"COMPLETO"}>
              {"0 REGISTROS"}
            </Tag>
          ) : (
            <Tag color={"red"} key={"COMPLETO"}>
              {"SIN EMPRENDIMIENTO"}
            </Tag>
          )}
        </div>
      ),
      filters: [
        { text: "CON REGISTROS", value: "1" },
        { text: "SIN REGISTROS", value: "2" },
        { text: "SIN EMPRENDIMIENTO", value: "0" },
      ],
      onFilter: (value, record) => {
        if (value === "1") {
          if (record.entrepreneurships.length > 0) {
            return record.entrepreneurships[0].needs.length > 0;
          } else {
            return false;
          }
        } else if (value === "2") {
          if (record.entrepreneurships.length > 0) {
            return record.entrepreneurships[0].needs.length === 0;
          } else {
            return false;
          }
        } else {
          return record.entrepreneurships.length === 0;
        }
      },
    },
    {
      title: "Encuesta",
      key: "action",
      width: 180,
      render: (_, record) => (
        <div>
          {record.entrepreneurships.length > 0 &&
          record.entrepreneurships[0].question1 !== null ? (
            <Tag color={"green"} key={"COMPLETO"}>
              {"COMPLETO"}
            </Tag>
          ) : (
            <Tag color={"red"} key={"COMPLETO"}>
              {"SIN ENCUESTA"}
            </Tag>
          )}
        </div>
      ),
      filters: [
        { text: "COMPLETO", value: "1" },
        { text: "SIN ENCUESTA", value: "0" },
      ],
      onFilter: (value, record) => {
        if (value === "1") {
          if (record.entrepreneurships.length > 0) {
            return record.entrepreneurships[0].question1 !== null;
          } else {
            return false;
          }
        } else {
          if (record.entrepreneurships.length > 0) {
            return record.entrepreneurships[0].question1 === null;
          } else {
            return true;
          }
        }
      },
    },
  ];

  const fields: ITableExportFields = {
    ci: "CI",
    name: "Nombres",
    lastname: "Apellidos",
    usuario: {
      header: "Usuario",
      formatter: (_fieldValue, record) => {
        return "COMPLETO";
      },
    },
    emprendimiento: {
      header: "Emprendimiento",
      formatter: (_fieldValue, record) => {
        if (record.entrepreneurships.length > 0) {
          return "COMPLETO";
        } else {
          return "NO REGISTRADO";
        }
      },
    },
    haves: {
      header: "Yo tengo",
      formatter: (_fieldValue, record) => {
        if (record.entrepreneurships.length > 0) {
          return record.entrepreneurships[0].haves.length + " REGISTROS";
        } else {
          return "SIN EMPRENDIMIENTO";
        }
      },
    },
    needs: {
      header: "Yo necesito",
      formatter: (_fieldValue, record) => {
        if (record.entrepreneurships.length > 0) {
          return record.entrepreneurships[0].needs.length + " REGISTROS";
        } else {
          return "SIN EMPRENDIMIENTO";
        }
      },
    },
    encuesta: {
      header: "Encuesta",
      formatter: (_fieldValue, record) => {
        if (record.entrepreneurships.length > 0) {
          if (record.entrepreneurships[0].question1 !== null) {
            return "COMPLETO";
          }
          return "SIN ENCUESTA";
        } else {
          return "SIN EMPRENDIMIENTO";
        }
      },
    },
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Reportes</Breadcrumb.Item>
        <Breadcrumb.Item>Estado de registros</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <br />
        <h2>Estado de los registros de emprendimientos</h2>
        <Form
          name="filters"
          initialValues={{ remember: false }}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectProvinceAllForm
                atributoData={province}
                setAtributoData={setProvince}
              ></SelectProvinceAllForm>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectAllCityForm
                atributoData={city}
                setAtributoData={setCity}
                provinceData={province}
              ></SelectAllCityForm>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectRoleOneForm
                atributoData={role}
                setAtributoData={setRole}
              ></SelectRoleOneForm>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectCommunityOneForm
                atributoData={community}
                setAtributoData={setCommunity}
              ></SelectCommunityOneForm>
            </Col>
          </Row>
        </Form>
        {/* <Button type="primary" onClick={() => exportTable()}>
          Exportar <ExportOutlined />
        </Button>
        <br />
        <br /> */}
        <Table
          columns={columns}
          dataSource={usersActive}
          loading={loadingData}
          rowKey="id"
          scroll={{ x: 1500 }}
          size="small"
          //onChange={onChange}
          exportable
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} de ${total} registros`,
          }}
          exportableProps={{
            fields,
            fileName: "Estado de registros",
            btnProps: {
              type: "primary",
              icon: <ExportOutlined />,
              children: <span>Exportar</span>,
            },
            children: <span>Exportar</span>,
          }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No existen registros"
              />
            ),
          }}
        />
      </div>
    </div>
  );
}
