import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import routes from "./config/routes";
import AuthProvider from "./providers/authProvider";
import socketClient from "socket.io-client";
import { basePath } from "./api/config";
//Layout
import LayoutAdmin from "./layouts/LayoutAdmin";
import LayoutBasic from "./layouts/LayoutBasic";
import LayoutUser from "./layouts/LayoutUser";

import AdminHome from "./pages/Admin";
import AdminSignIn from "./pages/Admin/SignIn";
import AdminUsers from "./pages/Admin/Users";
import AdminSectores from "./pages/Admin/Reportes/Sectores";
import AdminDiagnostico from "./pages/Admin/Reportes/Diagnostico";
import AdminDiagnosticoBlandas from "./pages/Admin/Reportes/DiagnosticoBlandas";
import AdminDiagnosticoEtapas from "./pages/Admin/Reportes/DiagnosticoEtapas";
import AdminEtapas from "./pages/Admin/Reportes/Etapas";
import AdminCiudades from "./pages/Admin/Reportes/Ciudades";
import AdminProvincias from "./pages/Admin/Reportes/Provincias";
import AdminRegistros from "./pages/Admin/Reportes/Registros";
import AdminConocimientos from "./pages/Admin/Reportes/Conocimientos";
import AdminOferta from "./pages/Admin/Reportes/Oferta";
import AdminCapital from "./pages/Admin/Reportes/Capital";
import AdminFacturacion from "./pages/Admin/Reportes/Facturacion";
import AdminEmpleados from "./pages/Admin/Reportes/Empleo";
import AdminInversion from "./pages/Admin/Reportes/Inversion";
import AdminMapaUsuario from "./pages/Admin/Reportes/MapaUsuario";
import AdminMapaEmprendimientos from "./pages/Admin/Reportes/MapaUsuarioEmprendimientos";

import HomeUser from "./pages/User";
import PersonalUser from "./pages/User/Personal";
import EmprendimientoUser from "./pages/User/Emprendimiento";
import HaveUser from "./pages/User/Have";
import NeedUser from "./pages/User/Need";
import ChangeUser from "./pages/User/Change";
import ConnectionsUser from "./pages/User/Conexiones";
import ChatUser from "./pages/User/Chat";

import Home from "./pages/Home";
// import Contact from "../pages/Contact";

//Other
import Error404 from "./pages/Error404";

import "./App.scss";

function App() {
  var socket = socketClient(basePath, {
    transports: ["websocket", "polling", "flashsocket"],
  });

  //var socket = io('http://yourDomain:port', { transports: ['websocket', 'polling', 'flashsocket'] });

  socket.on("connection", () => {
    console.log(`I'm connected with the back-end`);
  });
  return (
    <AuthProvider>
      {/* <Router>
        <Routes>
          {routes.map((route, index) => (
            <RouterWithSubRoutes key={index} {...route} />
          ))}
        </Routes>
      </Router> */}
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Error404 />} />
          <Route element={<LayoutBasic />}>
            {/* <Route index element={<Home />} /> */}
            {/* <Route path="/*" element={<Error404 />} /> */}
            <Route path="/admin/login" element={<AdminSignIn />} />
            <Route path="/" element={<Home />} />
          </Route>
          <Route element={<LayoutAdmin />}>
            {/* <Route path="/admin/*" element={<Error404 />} /> */}
            <Route path="/admin" element={<AdminHome />} />
            <Route path="/admin/users" element={<AdminUsers />} />
            <Route path="/admin/sectores" element={<AdminSectores />} />
            <Route path="/admin/diagnostico" element={<AdminDiagnostico />} />
            <Route
              path="/admin/diagnosticoblandas"
              element={<AdminDiagnosticoBlandas />}
            />
            <Route
              path="/admin/diagnosticoetapas"
              element={<AdminDiagnosticoEtapas />}
            />
            <Route path="/admin/etapas" element={<AdminEtapas />} />
            <Route path="/admin/ciudades" element={<AdminCiudades />} />
            <Route path="/admin/provincias" element={<AdminProvincias />} />
            <Route path="/admin/registros" element={<AdminRegistros />} />
            <Route
              path="/admin/conocimientos"
              element={<AdminConocimientos />}
            />
            <Route path="/admin/oferta" element={<AdminOferta />} />
            <Route path="/admin/capital" element={<AdminFacturacion />} />
            <Route path="/admin/facturacion" element={<AdminCapital />} />
            <Route path="/admin/empleo" element={<AdminEmpleados />} />
            <Route path="/admin/inversion" element={<AdminInversion />} />
            <Route path="/admin/mapusuario" element={<AdminMapaUsuario />} />
            <Route
              path="/admin/mapemprendimiento"
              element={<AdminMapaEmprendimientos />}
            />
          </Route>
          <Route element={<LayoutUser socket={socket} />}>
            <Route path="/user/*" element={<Error404 />} />
            <Route path="/user" socket={socket} element={<HomeUser />} />
            <Route
              path="/user/personal"
              socket={socket}
              element={<PersonalUser />}
            />
            <Route
              path="/user/emprendimiento"
              socket={socket}
              element={<EmprendimientoUser />}
            />
            <Route
              path="/user/yotengo"
              socket={socket}
              element={<HaveUser />}
            />
            <Route
              path="/user/yonecesito"
              socket={socket}
              element={<NeedUser />}
            />
            <Route
              path="/user/change"
              socket={socket}
              element={<ChangeUser />}
            />
            <Route
              path="/user/conexiones"
              element={<ConnectionsUser socket={socket} />}
            />
            <Route path="/user/chat" element={<ChatUser socket={socket} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

// function RouterWithSubRoutes(route) {
//   return (
//     <Route
//       path={route.path}
//       //exact={route.exact}
//       render={(props) => <route.component routes={route.routes} {...props} />}
//     />
//   );
// }

export default App;
