import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "antd";

import "./LayoutBasic.scss";

import Home from "../pages/Home";
import AdminSignIn from "../pages/Admin/SignIn";
// import Contact from "../pages/Contact";

//Other
import Error404 from "../pages/Error404";

export default function LayoutBasic(props) {
  const { routes } = props;
  const { Content, Footer } = Layout;

  return (
    <Layout className="layoutbasic">
      <Layout>
        <Content>
          {/* <LoadRoutes routes={routes} /> */}

          <Routes>
            <Route path="*" element={<Error404 />} />
            <Route path="/" element={<Home />} />
            <Route path="/admin/login" element={<AdminSignIn />} />
          </Routes>
        </Content>
        <Footer className="layoutbasic__footer">
          © Copyright IICA - Innovando Amazonía. <br></br>All Rights Reserved |
          Versión 1.0
        </Footer>
      </Layout>
    </Layout>
  );
}

function LoadRoutes({ routes }) {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          //component={route.component}
          element={<route.component />}
        />
      ))}
    </Routes>
  );
}
