import React, { useState, useEffect } from "react";
import { Navigate, Routes, Route, BrowserRouter } from "react-router-dom";
import { Layout } from "antd";
import MenuTop from "../components/Admin/MenuTop";
import MenuSiderUser from "../components/Home/User/MenuSider";
import AdminSignIn from "../pages/Admin/SignIn";
//import Admin from "../pages/Admin";
//import UseAuth from "../hooks/useAuth";

//import { getAccessToken, getRefreshToken } from "../api/auth";

import "./LayoutUser.scss";
import useAuth from "../hooks/useAuth";

import HomeUser from "../pages/User";
import PersonalUser from "../pages/User/Personal";
import EmprendimientoUser from "../pages/User/Emprendimiento";
import Error404 from "../pages/Error404";
import HaveUser from "../pages/User/Have";
import NeedUser from "../pages/User/Need";
import ChangeUser from "../pages/User/Change";
import ConnectionsUser from "../pages/User/Conexiones";
import ChatUser from "../pages/User/Chat";

export default function LayoutUser(props) {
  const { socket } = props;
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const { Header, Content, Footer } = Layout;

  const { user, isLoading } = useAuth(); //hook

  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
    if (width < 756) {
      setMenuCollapsed(true);
    }
  }, []);

  //console.log(user);
  if (!user && !isLoading) {
    return (
      <>
        <Routes>
          <Route path="/admin/login" element={<AdminSignIn />}></Route>
          {/* <Navigate to="/admin/login"></Navigate> */}
        </Routes>

        <Navigate to="/admin/login"></Navigate>
      </>
    );
  }

  if (user && !isLoading) {
    return (
      <Layout>
        <MenuSiderUser menuCollapsed={menuCollapsed} />
        <Layout
          className="layout-user"
          //style={{ marginLeft: menuCollapsed ? "80px" : "256px" }}
        >
          <Header className="layout-user__header">
            <MenuTop
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            />
          </Header>

          <Content className="layout-user__content">
            {/* <LoadRoutes routes={routes} /> */}
            <Routes>
              <Route path="/user/*" element={<Error404 />} />
              <Route path="/user" element={<HomeUser socket={socket} />} />
              <Route
                path="/user/personal"
                element={<PersonalUser socket={socket} />}
              />
              <Route
                path="/user/emprendimiento"
                element={<EmprendimientoUser socket={socket} />}
              />
              <Route
                path="/user/yotengo"
                element={<HaveUser socket={socket} />}
              />
              <Route
                path="/user/yonecesito"
                element={<NeedUser socket={socket} />}
              />
              <Route
                path="/user/change"
                element={<ChangeUser socket={socket} />}
              />
              <Route
                path="/user/conexiones"
                element={<ConnectionsUser socket={socket} />}
              />
              <Route path="/user/chat" element={<ChatUser socket={socket} />} />
            </Routes>
          </Content>
          <Footer className="layout-user__footer">
            © Copyright IICA - Innovando Amazonía. <br></br>All Rights Reserved
            | Versión 1.0
          </Footer>
        </Layout>
      </Layout>
    );
  }

  return null;
}

// function LoadRoutes(routes) {
//   return (
//     // <BrowserRouter>
//     //   <Routes>
//     //     <Route path="/" element={<Home />} />
//     //     <Route path="users" element={<Users />}>
//     //       <Route path="me" element={<OwnUserProfile />} />
//     //       <Route path=":id" element={<UserProfile />} />
//     //     </Route>
//     //   </Routes>
//     // </BrowserRouter>
//     <Routes>
//       {routes.map((route, index) => (
//         <Route
//           key={index}
//           path={route.path}
//           //exact={route.exact}
//           //component={route.component}
//           element={route.component}
//         />
//       ))}
//     </Routes>
//   );
// }
