import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Row, Col, DatePicker, Button } from "antd";
import { Pie } from "@ant-design/charts";
import { getAccessTokenApi } from "../../../api/auth";

import "./Indicadores.scss";
//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

import { getNeedGroupApi } from "../../../api/need.api";
import { getHaveGroupApi } from "../../../api/have.api";

export default function Conocimientos() {
  const token = getAccessTokenApi();
  //const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [disabled2, setDisabled2] = useState(true);

  useEffect(() => {
    // if (fincaId.length > 0) {
    //   if (fechaInicial && fechaFinal) {
    //     getFincasAmbitoAvgApi(token, fechaInicial, fechaFinal, fincaId).then(
    //       (response) => {
    //         setData(response.fincas);
    //       }
    //     );
    //   } else {
    //     getFincasAmbitoAvgApi(token, 0, 0, fincaId).then((response) => {
    //       setData(response.fincas);
    //     });
    //   }
    // } else {
    //   if (fechaInicial && fechaFinal) {
    //     getFincasAmbitoAvgApi(token, fechaInicial, fechaFinal, 0).then(
    //       (response) => {
    //         setData(response.fincas);
    //       }
    //     );
    //   } else {
    //     getFincasAmbitoAvgApi(token, 0, 0, 0).then((response) => {
    //       setData(response.fincas);
    //     });
    //   }
    // }
    getNeedGroupApi(token).then((response) => {
      console.log(response.needs);
      setData(response.needs);
    });
    getHaveGroupApi(token).then((response2) => {
      console.log(response2.haves);
      setData2(response2.haves);
    });
  }, [token]);

  const config = {
    appendPadding: 10,
    data,
    angleField: "count",
    colorField: "name",
    radius: 0.75,
    label: {
      type: "spider",
      labelHeight: 28,
      content: "{name} - {value} horas\n{percentage}",
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  const config2 = {
    appendPadding: 10,
    //data2,
    angleField: "count",
    colorField: "name",
    radius: 0.75,
    label: {
      type: "spider",
      labelHeight: 28,
      content: "{name} - {value} horas\n{percentage}",
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  // function onChange(date, dateString) {
  //   if (date) {
  //     setFechaInicial(date[0].startOf("month").format("YYYY-MM-DD"));
  //     setFechaFinal(date[1].endOf("month").format("YYYY-MM-DD"));
  //   } else {
  //     setFechaInicial(null);
  //     setFechaFinal(null);
  //   }
  // }

  const ref = useRef([]);

  // export image
  const downloadImage = () => {
    ref.current[0]?.downloadImage();
  };

  // export image
  const downloadImage2 = () => {
    ref.current[1]?.downloadImage();
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Gráficas</Breadcrumb.Item>
        <Breadcrumb.Item>Conocimientos</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <Row gutter={24}>
          {/* <Row gutter={16}>
          <Col span={12}>
            <RangePicker
              style={{ width: "100%" }}
              picker="month"
              onChange={onChange}
            />
          </Col>
          <Col span={12}>
            { <SelectMultiFinca setFincaId={setFincaId}></SelectMultiFinca> }
          </Col>
        </Row> */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
            <h2>Horas de conocimientos en demanda</h2>

            <Pie
              {...config}
              onReady={(plot) => {
                ref.current[0] = plot;
                setDisabled(false);
              }}
            />
            <br />
            <Button
              disabled={disabled}
              htmlType="button"
              className="btn-submit"
              onClick={downloadImage}
            >
              Guardar gráfico
            </Button>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
            <h2>Horas de conocimientos en oferta</h2>

            <Pie
              data={data2 ?? []}
              {...config2}
              onReady={(plot) => {
                ref.current[1] = plot;
                setDisabled2(false);
              }}
            />
            <br />
            <Button
              disabled={disabled2}
              htmlType="button"
              className="btn-submit"
              onClick={downloadImage2}
            >
              Guardar gráfico
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}
