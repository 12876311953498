import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";

import { getAcademysApi } from "../../../../api/academy.api";

import "./SelectAcademyForm.scss";

const { Option } = Select;

export default function SelectAcademyForm(props) {
  const { atributoData, setAtributoData } = props;
  const [academyData, setAcademyData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [academyId, setAcademyId] = useState();

  useEffect(() => {
    getAcademysApi().then((response) => {
      setAcademyData(response.academys);
      //setAcademyId(atributoData);
    });
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value) => {
    setAcademyId(value);
    setAtributoData(value);
  };

  return (
    <div className="select-academy-form">
      <Form.Item
        label="¿Estuvo en una academia?"
        name="academy"
        rules={[{ required: true, message: "Seleccione una academia" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
      >
        <Select
          placeholder="Seleccione una academia"
          value={academyId}
          onChange={handleAtributoChange}
          //disabled={disabled}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {academyData && academyData.map
            ? academyData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
    </div>
  );
}
