import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Row, Col, DatePicker, Button } from "antd";
import { Bar } from "@ant-design/charts";
import { getAccessTokenApi } from "../../../api/auth";

import "./Indicadores.scss";
//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

import { getProvincesGroupApi } from "../../../api/province.api";

export default function Provincias() {
  const token = getAccessTokenApi();
  //const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    getProvincesGroupApi(token).then((response) => {
      console.log(response);
      setData(response.provinces);
    });
  }, [token]);

  const config = {
    data,
    xField: "value",
    yField: "type",
    seriesField: "type",
    legend: {
      position: "top-left",
    },
    label: {
      position: "middle",
      style: {
        fill: "#000",
        opacity: 1,
      },
    },
  };

  // function onChange(date, dateString) {
  //   if (date) {
  //     setFechaInicial(date[0].startOf("month").format("YYYY-MM-DD"));
  //     setFechaFinal(date[1].endOf("month").format("YYYY-MM-DD"));
  //   } else {
  //     setFechaInicial(null);
  //     setFechaFinal(null);
  //   }
  // }

  const ref = useRef();

  // export image
  const downloadImage = () => {
    ref.current?.downloadImage();
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Gráficas</Breadcrumb.Item>
        <Breadcrumb.Item>Provincias</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        {/* <Row gutter={16}>
          <Col span={12}>
            <RangePicker
              style={{ width: "100%" }}
              picker="month"
              onChange={onChange}
            />
          </Col>
          <Col span={12}>
            { <SelectMultiFinca setFincaId={setFincaId}></SelectMultiFinca> }
          </Col>
        </Row> */}

        <br />
        <h2>Total de emprendimientos por provincia</h2>
        <Bar
          {...config}
          onReady={(plot) => {
            ref.current = plot;
            setDisabled(false);
          }}
        />
        <br />
        <Button
          disabled={disabled}
          htmlType="button"
          className="btn-submit"
          onClick={downloadImage}
        >
          Guardar gráfico
        </Button>
      </div>
    </div>
  );
}
